import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store/index.js'
Vue.use(VueI18n)

// 引入各个语言配置文件
import zh from './zh'
import en from './en'
import tw from './tw'
import zht from './zht'
// import store from '@/store/index.js'

import locale from 'element-ui/lib/locale'

// 创建vue-i18n实例i18n
const i18n = new VueI18n({
  // 设置默认语言
  locale: store.getters.lang, // 语言标识
  // 添加多语言（每一个语言标示对应一个语言文件）
  messages: {
    zh,
    en,
    tw,
    zht
  }
})

locale.i18n((key, value) => i18n.t(key, value))


// 暴露i18n
export default i18n