import Vue from 'vue'
import Router from 'vue-router'
import { Loading,Message } from 'element-ui';

// import Loading from 'element-ui/packages/loading';

// 导入组件
import LoginPage from '../views/Login/index.vue'
import LoginHybridPage from '../views/LoginHybrid/index.vue'
import RegisterPage from '../views/Register/index.vue'
import ForgotPage from '../views/Forgot/index.vue'
import ReleaseHistoryPage from '../views/ReleaseHistory/index.vue'
import ForgotAccoutPage from '../views/ForgotAccout/index.vue'
import HomePage from '../views/Home/index.vue'
import PropertyListPage from '../views/PropertyList/index.vue'
import UserinfoPage from '../views/Userinfo/index.vue'
import NoticePage from '../views/Notice/index.vue'
import CommonReasonPage from '../views/CommonReason/index.vue'
import SlaPage from '../views/Sla/index.vue'
import VisitorQuestionTmplPage from '../views/VisitorQuestionTmpl/index.vue'
import VisitorQuestionPage from '../views/VisitorQuestion/index.vue'
import SSOPage from '../views/UserinfoSsoConfig/index.vue'

Vue.use(Router)

// 懒加载路由
const lazyLoading = async (resolve, componentName) => {
  let loadingInstance = Loading.service();
  let result = await import(`@/views/${componentName}/index.vue`)
  resolve(result.default)
  loadingInstance.close()
}

const router = new Router({
  routes: [
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '/login', component: LoginPage,
      meta: {
        disabledAuth: true
      },
    },
    {
      path: '/login_hybrid', component: LoginHybridPage,
      meta: {
        disabledAuth: true
      },
    },
    {
      path: '/register', component: RegisterPage,
      meta: {
        disabledAuth: true
      },
    },
    {
      path: '/forgot', component: ForgotPage,
      meta: {
        disabledAuth: true
      },
    },
    {
      path: '/forgot_accout', component: ForgotAccoutPage,
      meta: {
        disabledAuth: true
      },
    },
    { path: '/home', component: HomePage },
    { path: '/property_list', component: PropertyListPage },
    { path: '/userinfo', component: UserinfoPage },
    { path: '/notice', component: NoticePage },
    { path: '/release_history', component: ReleaseHistoryPage },
    { path: '/common_reason_list', component: CommonReasonPage },
    { path: '/sla', component: SlaPage },
    { path: '/visitor_question_tmpl_management', component: VisitorQuestionTmplPage },
    { path: '/visitor_question_management', component: VisitorQuestionPage },
    { path: '/sso', component: SSOPage },
    
    
    // PropertyEntity
    { path: '/basicinfo', component: resolve => lazyLoading(resolve, 'PropertyDetail') },
    { path: '/account', component: resolve => lazyLoading(resolve, 'Account') },
    { path: '/authmanagement', component: resolve => lazyLoading(resolve, 'AuthManagement') },
    { path: '/authority', component: resolve => lazyLoading(resolve, 'Authority') },
    { path: '/location', component: resolve => lazyLoading(resolve, 'Location') },
    { path: '/group', component: resolve => lazyLoading(resolve, 'Group') },
    { path: '/holiday', component: resolve => lazyLoading(resolve, 'Holiday') },
    { path: '/assets', component: resolve => lazyLoading(resolve, 'Assets') },
    { path: '/sensor', component: resolve => lazyLoading(resolve, 'Sensor') },
    { path: '/securityRules', component: resolve => lazyLoading(resolve, 'SecurityRules') },
    // InspectionPlan
    { path: '/task', component: resolve => lazyLoading(resolve, 'Task') },
    { path: '/create', component: resolve => lazyLoading(resolve, 'TaskCreate') },
    { path: '/updateTask', component: resolve => lazyLoading(resolve, 'TaskEdit') },
    { path: '/taskdetail', component: resolve => lazyLoading(resolve, 'TaskDetail') },
    { path: '/commonlist', component: resolve => lazyLoading(resolve, 'CommonList') },
    // InventoryMaterial
    { path: '/inventory_management', component: resolve => lazyLoading(resolve, 'InvManagement') },
    { path: '/inventory_material', component: resolve => lazyLoading(resolve, 'InvMaterial') },
    // MaterialUsageRecords
    { path: '/material_usage_record', component: resolve => lazyLoading(resolve, 'MaterialUsageRecords') },
    // WorkOrderTemplate
    { path: '/wotemplatelist', component: resolve => lazyLoading(resolve, 'WoTemplate') },
    { path: '/wotemplatedetail', component: resolve => lazyLoading(resolve, 'WoTemplateDetail') },
    { path: '/wotemplatecreate', component: resolve => lazyLoading(resolve, 'WoTemplateCreate') },
    { path: '/updateWOtemplate', component: resolve => lazyLoading(resolve, 'WoTemplateEdit') },
    { path: '/woCreate', component: resolve => lazyLoading(resolve, 'WoListCreate') },
    { path: '/wodetail', component: resolve => lazyLoading(resolve, 'WolistDetail') },
    { path: '/woEdit', component: resolve => lazyLoading(resolve, 'WoListEdit') },

    // PunchInTemplate
    { path: '/signintemplatelist', component: resolve => lazyLoading(resolve, 'PunchInTemplate') },
    { path: '/signintemplatecreate', component: resolve => lazyLoading(resolve, 'PunchInTemplateCreate') },
    { path: '/signintemplateupdate', component: resolve => lazyLoading(resolve, 'PunchInTemplateEdit') },

    // MonitorTemplate
    { path: '/monitorlist', component: resolve => lazyLoading(resolve, 'MonitorTemplate') },
    { path: '/monitorcreate', component: resolve => lazyLoading(resolve, 'MonitorTemplateCreate') },
    { path: '/updatemonitor', component: resolve => lazyLoading(resolve, 'MonitorTemplateEdit') },

    // HistoricalRecords
    { path: '/rcwo', component: resolve => lazyLoading(resolve, 'InspectionRecords') },
    { path: '/patrol_track', component: resolve => lazyLoading(resolve, 'TaskTrajectory') },
    { path: '/rcwodetail', component: resolve => lazyLoading(resolve, 'InspectionRecordsDetail') },
    { path: '/wolist', component: resolve => lazyLoading(resolve, 'WoList') },

    { path: '/signinlist', component: resolve => lazyLoading(resolve, 'SignInList') },
    { path: '/meterreadinglist', component: resolve => lazyLoading(resolve, 'SensorReading') },
    { path: '/meterreadingdetail', component: resolve => lazyLoading(resolve, 'SensorReadingDetail') },
    { path: '/event', component: resolve => lazyLoading(resolve, 'Event') },
    { path: '/datalist', component: resolve => lazyLoading(resolve, 'DataList') },
    { path: '/rcalert', component: resolve => lazyLoading(resolve, 'AlertHistory') },

    // VisitorManagement
    { path: '/guest_qrcode', component: resolve => lazyLoading(resolve, 'GuestQrcode') },
    { path: '/guest_reserve', component: resolve => lazyLoading(resolve, 'GuestReserve') },
    { path: '/visithistory', component: resolve => lazyLoading(resolve, 'GuestHistory') },
    {
      path: '/guest_register', component: resolve => lazyLoading(resolve, 'GuestRegister'),
      meta: {
        disabledAuth: true
      }
    },
    {
      path: '/showimage', component: resolve => lazyLoading(resolve, 'GuestShowImage'),
      meta: {
        disabledAuth: true
      }
    },


    // SupportingFunctions
    { path: '/worktablelog', component: resolve => lazyLoading(resolve, 'WorkTableLog') },

    // DocumentManagement
    { path: '/fileupload', component: resolve => lazyLoading(resolve, 'FileUpload') },
    { path: '/previewPage', component: resolve => lazyLoading(resolve, 'FileUploadPreview') },
    { path: '/fileuploadtable', component: resolve => lazyLoading(resolve, 'FileUploadTable') },
    { path: '/myfilehistory', component: resolve => lazyLoading(resolve, 'FileUploadMyHistory') },

    // StatisticsAnalysis
    { path: '/rptcenter', component: resolve => lazyLoading(resolve, 'RptCenter') },
    { path: '/rcwosum', component: resolve => lazyLoading(resolve, 'RcwoSum') },
    { path: '/eventsum', component: resolve => lazyLoading(resolve, 'EventSum') },
    { path: '/response_time_dashboard', component: resolve => lazyLoading(resolve, 'ResponseTimeDashboard') },
    { path: '/locsum', component: resolve => lazyLoading(resolve, 'LocSum') },
    { path: '/hrsum', component: resolve => lazyLoading(resolve, 'HrSum') },

    // InspectionMonitoring
    { path: '/inspection_monitoring', component: resolve => lazyLoading(resolve, 'InspectionMonitoring') },
    // safetyIncident
    { path: '/safety_incident', component: resolve => lazyLoading(resolve, 'SafetyIncident') },
    // reantalManagement
    { path: '/rent_property_list', component: resolve => lazyLoading(resolve, 'RentPropertyList') },
    { path: '/lend_return', component: resolve => lazyLoading(resolve, 'LendReturn') },
    { path: '/disposal_order', component: resolve => lazyLoading(resolve, 'DisposalOrder') },
  ]
})

// 跳转路由前执行的钩子函数
// to: 即将要进入的路由对象,from: 当前导航正要离开的路由,next:下一步执行的方法，参数是包含跳转路径的对象
router.beforeEach((to, from, next) => {
  //  判断是否需要登录
  if (!(typeof to.meta.disabledAuth == 'boolean' && to.meta.disabledAuth)) {
    const authKey = sessionStorage.getItem('authKey');
    const hrid = sessionStorage.getItem('loginid');
    if (!authKey || !hrid) {
      next({
        path: '/login',
        //  把当前页面的路由path作为参数传递给登录页面，登录成功后跳转到去往的页面
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    //  没有要求登录，直接跳转
    next();
  }
})

// router.onReady(() => {
//   const targetPath = sessionStorage.getItem('targetPath')
//   const tryReload = sessionStorage.getItem('tryReload')
//   if(targetPath) {
//     sessionStorage.removeItem('targetPath')
//     if(!tryReload) {
//       router.replace(targetPath)
//       sessionStorage.setItem('tryReload',true)
//     }else{
//       sessionStorage.removeItem('tryReload')
//     }
//   }
// })

// router.onError((error) => {
//   const jsPattern = /Loading chunk (.*) failed\./g;
//   const cssPattern = /Loading CSS chunk (\S)+ failed/g
//   const targetPath = router.history.pending.fullPath
//   if (jsPattern.test(error.message) || cssPattern.test(error.message)) {
//     console.log('router error', error)
//     Message({
//       type: 'error',
//       message: '服务器版本已更新，正在刷新本地缓存，请稍后...',
//       duration: 4000
//     })
//     sessionStorage.setItem('targetPath', targetPath)
//     window.location.reload(true);
//     // location.reload(true);
//   }
// })

router.onError((error) => {
  const jsPattern = /Loading chunk (.*) failed\./g;
  const cssPattern = /Loading CSS chunk (\S)+ failed/g
  
  if (jsPattern.test(error.message) || cssPattern.test(error.message)) {
    console.log('router error', error)
    Message({
      type: 'error',
      message: '服务器版本已更新，正在刷新本地缓存，请稍后...',
      duration: 4000
    })
    window.location.reload(true);
    // location.reload(true);
  }
})


export default router
