import Vue from 'vue'
import App from './App.vue'
import router from './router/'
import store from './store/'
import ElementUI from 'element-ui';
import https from '@/common/https'
import utils from '@/common/utils'
import i18n from './i18n/index'
import WebLayout from '@/components/WebLayout'
import PaginationBox from '@/components/PaginationBox'
import PubSub from "pubsub-js";
import { setLanguageChangeCallback } from "./common/LanguageSwitchManager"
import './assets/iconfont.css'

Vue.use(ElementUI);

// alert 弹窗配置，同一内容只能弹出一次
let alertMessages = {};
Vue.prototype.$alert = (message, title, options = {}) => {
  // 创建一个唯一的消息标识符  
  const msgKey = `${message}`;

  // 如果相同内容已弹出，则返回  
  if (alertMessages[msgKey]) {
    return
  }

  // 标记已展示该消息  
  alertMessages[msgKey] = true;

  return ElementUI.MessageBox.alert(message, title, {
    ...options,
  })
    .then(() => {
      return Promise.resolve();
    })
    .catch(() => {
      return Promise.reject();
    })
    .finally(() => {
      // 弹窗关闭后清除对应的消息，以允许再次弹窗  
      delete alertMessages[msgKey];  
    });
};

Vue.component('WebLayout', WebLayout);
Vue.component('PaginationBox', PaginationBox);

Vue.config.productionTip = false
Vue.prototype.$http = https
Vue.prototype.$utils = utils
Vue.prototype.$setLanguageChangeCallback = setLanguageChangeCallback
Vue.prototype.$pubsub = PubSub

new Vue({
  render: h => h(App),
  router,
  store,
  i18n
}).$mount('#app')
